module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Farm Credit Canada","short_name":"FCC","description":"The only lender 100% invested in Canadian agriculture and food.","lang":"en","start_url":"/en/","background_color":"#ffffff","theme_color":"#002136","display":"standalone","icon":"src/svgs/favicon.svg","localize":[{"start_url":"/fr/","lang":"fr","name":"Financement agricole Canada","short_name":"FAC","description":"Le seul prêteur entièrement dédié à l’agriculture et à l&#39;agroalimentaire au Canada."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8ca6a943c485298d4a6a19651908491f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
