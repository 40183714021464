exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-embeds-flv-js": () => import("./../../../src/pages/en/embeds/flv.js" /* webpackChunkName: "component---src-pages-en-embeds-flv-js" */),
  "component---src-pages-en-embeds-payment-chart-js": () => import("./../../../src/pages/en/embeds/payment-chart.js" /* webpackChunkName: "component---src-pages-en-embeds-payment-chart-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-en-topic-html-js": () => import("./../../../src/pages/en/topic.html.js" /* webpackChunkName: "component---src-pages-en-topic-html-js" */),
  "component---src-pages-fr-404-js": () => import("./../../../src/pages/fr/404.js" /* webpackChunkName: "component---src-pages-fr-404-js" */),
  "component---src-pages-fr-embeds-flv-js": () => import("./../../../src/pages/fr/embeds/flv.js" /* webpackChunkName: "component---src-pages-fr-embeds-flv-js" */),
  "component---src-pages-fr-embeds-payment-chart-js": () => import("./../../../src/pages/fr/embeds/payment-chart.js" /* webpackChunkName: "component---src-pages-fr-embeds-payment-chart-js" */),
  "component---src-pages-fr-recherche-js": () => import("./../../../src/pages/fr/recherche.js" /* webpackChunkName: "component---src-pages-fr-recherche-js" */),
  "component---src-pages-fr-topic-html-js": () => import("./../../../src/pages/fr/topic.html.js" /* webpackChunkName: "component---src-pages-fr-topic-html-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-public-tag-js": () => import("./../../../src/templates/public-tag.js" /* webpackChunkName: "component---src-templates-public-tag-js" */)
}

