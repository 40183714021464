import'./src/styles/global.css';

/*
export const shouldUpdateScroll = ({
  routerProps: { location },
  // getSavedScrollPosition
}) => {
  // const currentPosition = getSavedScrollPosition(location)
  // const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

  // window.scrollTo(...(currentPosition || [0, 0]))

  window.scrollTo([0,0]);
  return false
};
*/

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer];
  window.locations.push(window.location.href);
  window.previousPath = window.locations[window.locations.length - 2];
}